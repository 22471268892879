<template>
  <div v-if="($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') && $store.state.fastPermissoes.ativo == 'S'">
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li
              v-if="getUrlParameter('secretaria')"
              class="fd-app-breadcrumb-item"
            >
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li>
            <li
              v-else
              class="fd-app-breadcrumb-item"
            >
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
              >Modo admin</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Gerenciamento de alunos</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left mb-4">
                <h1 class="aluno_font_color">
                  Gerenciamento de
                  <span>alunos</span>
                </h1>
                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <section class="fd-app-welcome text-left mb-4">
                <div>
                  <b-button-group>
                    <b-dropdown
                      right
                      split
                      text="Personalizar emails"
                    >
                      <b-dropdown-item
                        v-if="fastEmailConvite.id_email"
                        @click.prevent="showModal('modalEditarEmailConvite')"
                      >
                        Email do convite
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="fastEmailConfirmacao.id_email"
                        @click.prevent="showModal('modalEditarEmailConfirmacao')"
                      >
                        Email de confirmação do convite
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-button-group>
                </div>
              </section>
              <!-- /fd-app-welcome -->
              <div class="row mt-4">
                <transition name="slide-fade">
                  <div
                    v-if="fastNavegacao.exibirAlunos"
                    class="col-12"
                  >                  
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-6 col-lg-6">
                            <h6 class="mx-0 my-2 font-weight-bold text-primary">
                              Lista de alunos da plataforma ({{ fastAlunosTotal.length }})
                            </h6>
                          </div>
                          <div class="col-sm-12 col-md-6 col-lg-6 text-right">
                            <button
                              class="btn btn-sm btn-primary col-sm-12 col-md-12 col-lg-4"
                              @click.prevent="showModal('modalTipoCadastro')"
                            >
                              <small>+ Cadastrar aluno</small>
                            </button>
                          </div>
                        </div>
                        
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12">
                              <input
                                type="text"
                                class="form-control-row form-control-sm col-sm-12 col-md-12 col-lg-12 mb-2"
                                placeholder="Filtrar por nome ou email"
                                v-model="fastFiltroNomeEmail"
                                @keyup="filtraAlunos"
                              >
                            <div class="col-sm-12 col-md-12 col-lg-12 flex">
                              <b-row align-h="between">
                                <div class="text-left col-lg-6 col-md-12 col-sm-12 p-0">
                                  <label class="text-secondary mb-0 mx-0 row">
                                    <small class="pl-0 ml-0 pl-0 col-8"><b>Filtrar por data:</b></small>
                                    <small
                                    class="col-4 text-right mr-0 pr-0"
                                    role="button"
                                    v-if="fastFiltroDataInicial || fastFiltroDataFinal" 
                                    @click="fastFiltroDataInicial = '', fastFiltroDataFinal = '', filtraAlunos()"
                                    >Limpar <b-icon icon="x-circle" scale="1.1" variant="danger"></b-icon></small>
                                  </label>
                                  <div class="d-flex">
                                    <b-form-datepicker 
                                    class="align-self-start"
                                    size="sm"
                                    v-model="fastFiltroDataInicial" 
                                    @input="filtraAlunos"
                                    locale="pt-BR"
                                    :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                                    label-no-date-selected="Selecionar data"
                                    placeholder="Selecionar data"
                                    label-help="Use o cursor para selecionar"
                                    ></b-form-datepicker>
                                    <small class="align-self-start pt-1 px-2">até</small>
                                    <b-form-datepicker 
                                    class="align-self-start"
                                    size="sm"
                                    v-model="fastFiltroDataFinal"
                                    @input="filtraAlunos"
                                    locale="pt-BR"
                                    :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                                    label-no-date-selected="Selecionar data"
                                    placeholder="Selecionar data"
                                    label-help="Use o cursor para selecionar"
                                    ></b-form-datepicker>
                                  </div>              
                                </div>
                                <div class="ml-lg-3">
                                  <label class="text-secondary m-0"><small><b>Matrícula Ativa:</b></small></label>
                                  <div class="row pl-3 pr-3">
                                    <b-form-checkbox v-model="fastFiltroMatriculaAtiva" @change="filtraAlunos" value="">Todos</b-form-checkbox>
                                    <b-form-checkbox v-model="fastFiltroMatriculaAtiva" @change="filtraAlunos" value="true" class="ml-3">Sim</b-form-checkbox>
                                    <b-form-checkbox v-model="fastFiltroMatriculaAtiva" @change="filtraAlunos" value="false" class="ml-3">Não</b-form-checkbox>
                                  </div>
                                </div>
                                <div class="ml-auto mt-3 text-right d-flex justify-content-end align-items-center">
                                  <a
                                    class="text-success fast-cursor-refresh"
                                    @click.prevent="getUsuariosPlataforma($route.params.id_plataforma, 'alunos')"
                                  >
                                    <b-icon-arrow-counterclockwise font-scale="2" />
                                  </a>
                                </div>
                              </b-row>
                            </div>
                          </div>
                          <div class="col-12 table-responsive mt-2">
                            <table class="table table-sm">
                              <thead class="thead-dark">
                                <tr>
                                  <th><small class="font-weight-bold">Nome</small></th>
                                  <th><small class="font-weight-bold">Email</small></th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Aceito</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Ativo</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Adicionado em</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Ações</small>
                                  </th>
                                </tr>
                              </thead>
                              <tbody v-if="fastAlunosLoading">
                                <tr>
                                  <td
                                    colspan="6"
                                    class="text-center"
                                    v-html="fastLoadingDados()"
                                  />
                                </tr>
                              </tbody>
                              <tbody v-else-if="fastAlunosPaginacao.length">
                                <tr
                                  v-for="(e, index) in fastAlunosPaginacao"
                                  :key="index"
                                >
                                  <td class="align-middle">
                                    <small>{{ e.nome_usuario }}</small>                                  
                                  </td>
                                  <td class="align-middle">
                                    <small>{{ e.email }}</small>                                  
                                  </td>
                                  <td class="align-middle text-center">
                                    <small
                                      v-if="e.ativo == 'S'"
                                      class="badge badge-success mr-2"
                                    >sim</small>
                                    <small
                                      v-else
                                      class="badge badge-danger mr-2"
                                    >não</small>                                  
                                  </td>
                                  <td class="align-middle text-center">
                                    <small
                                      v-if="e.ativo == 'S'"
                                      class="badge badge-success mr-2"
                                    >sim</small>
                                    <small
                                      v-else
                                      class="badge badge-danger mr-2"
                                    >Não</small>                               
                                  </td>
                                  <td class="align-middle text-center">
                                    <small v-if="e.data_convite_envio">{{ new Date(e.data_convite_envio).toLocaleDateString() }}</small>  
                                    <small v-else>Sem registro</small>                              
                                  </td>                           
                                  <td class="text-center align-middle text-nowrap">       
                                    <button
                                      class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                      @click="exibeModalEditarUsuario(e)"
                                    >
                                      <small>Detalhes</small>
                                    </button>  
                                    <a
                                      v-if="e.id_pessoa && getUrlParameter('secretaria')"
                                      :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria/aluno/' + e.id_pessoa"
                                      class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                      @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/aluno/' + e.id_pessoa)"
                                    >
                                      <small class="text-white">Editar dados</small>
                                    </a>   
                                    <button
                                      v-else-if="getUrlParameter('secretaria')"
                                      class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                      @click="criaUsuarioPessoa(e)"
                                    >
                                      <small>Criar pessoa</small>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody v-else>
                                <tr>
                                  <td
                                    colspan="6"
                                    class="text-center"
                                  >
                                    Nenhum aluno cadastrado na plataforma
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-12 text-center mt-4">
                            <Pagination
                              :page-size="20"
                              :items="fastAlunosFiltro"
                              @changePage="fastAlunosPaginacao = $event"
                            />
                          </div>
                        </div>
                      </div>
                    </div>                  
                  </div>
                </transition>
                <transition name="slide-fade">
                  <div
                    v-if="fastNavegacao.convidarAlunos"
                    class="col-12"
                  >                  
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-6">
                            <h6 class="m-0 font-weight-bold text-primary">
                              Convite de alunos por email <span v-if="fastEmailsConvite.length">({{ fastEmailsConvite.length }})</span>
                            </h6>
                          </div>                        
                          <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                            <button
                              v-if="!fastEmailsConvite.length"
                              class="btn btn-sm btn-primary"
                              @click="showModal('modalListaEmails')"
                            >
                              <small>+ Adicionar lista massiva</small>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="col-12 mt-2">
                          <div class="row">
                            <div class="col-12 table-responsive mt-2">
                              <table class="table table-sm">
                                <thead class="thead-dark">
                                  <tr>
                                    <th class="text-center">
                                      <span class="font-weight-bold">Email</span>
                                    </th>
                                    <th class="text-center">
                                      <span class="font-weight-bold">Status</span>
                                    </th>
                                    <th class="text-center">
                                      <span class="font-weight-bold">Ações</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(e, index) in fastEmailsConvite"
                                    :key="index"
                                  >
                                    <td class="text-center align-middle">
                                      <div class="text-center">
                                        <div :style="{ backgroundImage: 'url(' + ajustaLinkImageUser(e.image) + ')', width: '60px', height: '60px', margin: 'auto', marginBottom: '5px', borderRadius: '50%', backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%' }" />
                                        <small>{{ e.first_name + " " + e.last_name }}</small>                                    
                                      </div>
                                      <span>{{ e.email }}</span>                                  
                                    </td>
                                    <td class="text-center align-middle">
                                      <span :class="e.classe">{{ e.status }}</span>                                  
                                    </td>
                                    <td class="text-center align-middle">
                                      <button
                                        v-if="e.code != 'OK'"
                                        class="btn btn-sm btn-danger pt-0 pb-0"
                                        @click="removeEmailLista(e.email)"
                                      >
                                        <span>- Cancelar convite</span>
                                      </button>  
                                      <div
                                        v-if="e.code == 'email_on_system'"
                                        class="text-center"
                                      >
                                        <button
                                          class="btn btn-sm btn-success pt-0 pb-0 mt-2"
                                          @click="cadastrarUsuario('alunos', e)"
                                        >
                                          + Confirmar convite
                                        </button>  
                                      </div>
                                    </td>
                                  </tr>
                                  <tr v-if="fastBtnConvidar">
                                    <td class="text-center align-middle pt-2 pb-2">
                                      <input
                                        v-model="fastEmail.email"
                                        :class="!validateEmail(fastEmail.email) ? 'form-control border border-danger' : 'form-control border border-success'"
                                        type="text"
                                        class="form-control"
                                        placeholder="Insira um email"
                                      >
                                    </td>
                                    <td class="text-center align-middle">
                                      <span :class="fastEmail.classe">{{ fastEmail.status }}</span>
                                    </td>
                                    <td class="text-center align-middle">
                                      <button
                                        class="btn btn-sm btn-success pt-0 pb-0"
                                        @click="adicionaEmailLista()"
                                      >
                                        <span>+ Adicionar</span>
                                      </button>  
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>                                         
                            <div class="col-12 mt-2 mb-2">
                              <div class="form-group mb-0">
                                <button
                                  v-if="fastEmailsConvite.length && fastBtnConvidar"
                                  href="#"
                                  type="button"
                                  class="btn btn-primary mr-1"
                                  @click.prevent="convidarUsuarios()"
                                >
                                  <small>Enviar convites</small>                                
                                </button>
                                <button
                                  href="#"
                                  type="button"
                                  class="btn btn-secondary mr-1"
                                  @click.prevent="cancelaConviteUsuarios()"
                                >
                                  <small>Voltar</small>                                
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
                <transition name="slide-fade">
                  <div
                    v-if="fastNavegacao.cadastrarAluno"
                    class="col-12"
                  >                  
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <h6 class="m-0 font-weight-bold text-primary">
                              Cadastrar novo aluno
                            </h6>
                          </div>                        
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="col-12 mt-2">
                          <!-- Usuário já cadastrado no sistema ou na plataforma não pode ter seus dados alterados -->
                          <div
                            v-if="fastEmailCadastro.code != 'email_on_system' && fastEmailCadastro.code != 'email_on_plataform'"
                            class="row"
                          >
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Nome do aluno <small class="text-danger">*</small></label>
                              <input
                                v-model="modelCadastroUsuario.fast_usuario.first_name"
                                type="text"
                                class="form-control"
                                maxlength="50"
                                :class="!modelCadastroUsuario.fast_usuario.first_name ? 'form-control border border-danger' : 'form-control'"
                              >
                            </div>   
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Sobrenome do aluno <small class="text-danger">*</small></label>
                              <input
                                v-model="modelCadastroUsuario.fast_usuario.last_name"
                                type="text"
                                class="form-control"
                                maxlength="50"
                                :class="!modelCadastroUsuario.fast_usuario.last_name ? 'form-control border border-danger' : 'form-control'"
                              >
                            </div>   
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Email <small class="text-danger">*</small></label>
                              <input
                                v-model="modelCadastroUsuario.fast_usuario.email"
                                type="text"
                                class="form-control"
                                maxlength="50"
                                :class="!modelCadastroUsuario.fast_usuario.email ? 'form-control border border-danger' : 'form-control'"
                              >
                            </div>           
                            <div class="col-sm-12 col-md-12 col-lg-6 text-center mb-4">
                              <div class="fd-app-curso-aulas mt-0">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                                      <div class="col-12 text-center mb-1">
                                        <span>Enviar convite por email</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input   
                                            v-model="modelCadastroUsuario.envia_email"                                        
                                            type="checkbox"                                           
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>  
                            </div>   
                          </div>  
                          <!-- Usuário existe -->
                          <div
                            v-else
                            class="row"
                          >
                            <div class="col-12 mb-2 text-center">
                              <div class="text-center">
                                <div :style="{ backgroundImage: 'url(' + ajustaLinkImageUser(fastEmailCadastro.image) + ')', width: '60px', height: '60px', margin: 'auto', marginBottom: '5px', borderRadius: '50%', backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%' }" />
                                <small>{{ fastEmailCadastro.first_name + " " + fastEmailCadastro.last_name }}</small>                                    
                              </div>
                              <p>{{ fastEmailCadastro.email }}</p> 
                              <div
                                v-if="fastEmailCadastro.administrador == 'S'"
                                class="text-success"
                              >
                                <b-icon-check-circle-fill /> Administrador
                              </div>
                              <div
                                v-if="fastEmailCadastro.professor == 'S'"
                                class="text-success"
                              >
                                <b-icon-check-circle-fill /> Professor
                              </div>
                              <div
                                v-if="fastEmailCadastro.aluno == 'S'"
                                class="text-success"
                              >
                                <b-icon-check-circle-fill /> Aluno
                              </div>
                              <div
                                v-if="fastEmailCadastro.tesouraria == 'S'"
                                class="text-success"
                              >
                                <b-icon-check-circle-fill /> Tesouraria
                              </div>
                              <div
                                v-if="fastEmailCadastro.secretario == 'S'"
                                class="text-success"
                              >
                                <b-icon-check-circle-fill /> Secretario
                              </div>
                              <div
                                v-if="fastEmailCadastro.responsavel == 'S'"
                                class="text-success"
                              >
                                <b-icon-check-circle-fill /> Responsavel
                              </div> 

                              <!-- O convite para um usuário existente só pode ser feito se o usuário não estiver na plataforma -->
                              <button
                                v-if="fastEmailCadastro.code != 'email_on_plataform'"
                                class="btn btn-sm btn-success pt-0 pb-0 mt-2"
                                @click="cadastrarUsuarioCompleto('alunos', fastEmailCadastro)"
                              >
                                + Confirmar cadastro
                              </button> 
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12 text-center">
                              <p :class="fastEmailCadastro.classe">
                                {{ fastEmailCadastro.status }}
                              </p>                            
                            </div>
                          </div>
                          <div class="row">                                        
                            <div class="col-12 mt-2 mb-2">
                              <div class="form-group mb-0">
                                <button
                                  v-if="fastEmailCadastro.btnCadastrar"
                                  href="#"
                                  type="button"
                                  class="btn btn-primary mr-1"
                                  @click="verificaCadastrarUsuarioCompleto('alunos')"
                                >
                                  <small>Cadastrar usuário</small>                                
                                </button>
                                <button
                                  href="#"
                                  type="button"
                                  class="btn btn-secondary mr-1"
                                  @click.prevent="cancelaConviteUsuarios()"
                                >
                                  <small>Cancelar</small>                                
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
                <transition name="slide-fade">
                  <div
                    v-if="fastNavegacao.editarAluno"
                    class="col-12"
                  >
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <h6 class="m-0 font-weight-bold text-primary">
                              Detalhes do aluno
                            </h6>
                          </div>                        
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="col-12 mt-2">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6">
                              <div class="card border-left-primary h-100">                    
                                <div class="card-body">
                                  <div class="row no-gutters align-items-center">
                                    <div class="col mr-2">
                                      <div class="text-xs font-weight-bold text-secondary text-uppercase mb-1">                                              
                                        <span v-if="fastUsuarioEditar.first_name">{{ fastUsuarioEditar.first_name + " " + fastUsuarioEditar.last_name }}</span>     
                                        <span v-else>Nome não registrado</span>  
                                      </div>
                                      <div class="h5 mb-0 font-weight-bold text-success">
                                        <span>{{ fastUsuarioEditar.email }}</span>  
                                      </div>
                                      <div class="text-xs font-weight-bold text-secondary text-uppercase mt-2">   
                                        <div
                                          v-if="fastUsuarioEditar.administrador == 'S'"
                                          class="text-success"
                                        >
                                          <b-icon-check-circle-fill /> Administrador
                                        </div>
                                        <div
                                          v-if="fastUsuarioEditar.professor == 'S'"
                                          class="text-success"
                                        >
                                          <b-icon-check-circle-fill /> Professor
                                        </div>
                                        <div
                                          v-if="fastUsuarioEditar.aluno == 'S'"
                                          class="text-success"
                                        >
                                          <b-icon-check-circle-fill /> Aluno
                                        </div>
                                        <div
                                          v-if="fastUsuarioEditar.tesouraria == 'S'"
                                          class="text-success"
                                        >
                                          <b-icon-check-circle-fill /> Tesouraria
                                        </div>
                                        <div
                                          v-if="fastUsuarioEditar.secretario == 'S'"
                                          class="text-success"
                                        >
                                          <b-icon-check-circle-fill /> Secretario
                                        </div>
                                        <div
                                          v-if="fastUsuarioEditar.responsavel == 'S'"
                                          class="text-success"
                                        >
                                          <b-icon-check-circle-fill /> Responsavel
                                        </div>                                                                                             
                                      </div>
                                    </div>
                                    <div class="col-auto">
                                      <div :style="{ backgroundImage: 'url(' + ajustaLinkImageUser(fastUsuarioEditar.image) + ')', width: '60px', height: '60px', margin: 'auto', marginBottom: '5px', borderRadius: '50%', backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%' }" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-md-12 col-lg-6">
                              <div class="card border-left-primary h-100">                    
                                <div class="card-body">
                                  <div class="row no-gutters align-items-center">
                                    <div class="col mr-2">
                                      <div
                                        v-if="fastUsuarioEditar.data_convite_envio"
                                        class="text-xs font-weight-bold text-secondary text-uppercase mb-1"
                                      >                                              
                                        Adicionado em <span>{{ new Date(fastUsuarioEditar.data_convite_envio).toLocaleDateString() }}</span>  
                                      </div>
                                      <div
                                        v-if="fastUsuarioEditar.data_convite_reenvio"
                                        class="text-xs font-weight-bold text-secondary text-uppercase mb-1"
                                      >                                              
                                        Reenviado em <span>{{ new Date(fastUsuarioEditar.data_convite_reenvio).toLocaleDateString() }}</span>  
                                      </div>
                                      <div class="text-xs font-weight-bold text-secondary text-uppercase mb-1">                                              
                                        Aceito: <span
                                          v-if="fastUsuarioEditar.aceito == 'S'"
                                          class="text-success"
                                        >Sim</span>  
                                        <span
                                          v-else
                                          class="text-danger"
                                        >Não</span>                                                
                                      </div>
                                      <div
                                        v-if="fastUsuarioEditar.data_aceite && fastUsuarioEditar.aceito == 'S'"
                                        class="text-xs font-weight-bold text-secondary text-uppercase mb-1"
                                      >                                              
                                        Data do aceite: <span>{{ new Date(fastUsuarioEditar.data_aceite).toLocaleDateString() }}</span>                                                 
                                      </div>
                                      <div
                                        v-if="fastUsuarioEditar.token"
                                        class="text-xs font-weight-bold text-secondary text-uppercase mb-1"
                                      >                                              
                                        <p v-if="fastUsuarioEditar.token == 'desativado'">
                                          <span class="text-danger">Desativado manualmente</span>
                                        </p> 
                                        <p v-else-if="fastUsuarioEditar.token == 'ativado'">
                                          <span class="text-success">Ativado manualmente</span>
                                        </p> 
                                        <div v-else>
                                          <p>Convite pendente: <span class="text-warning"> Sim </span></p>
                                            <p v-if="fastLinkConvite.url">
                                              Link do convite: 
                                              <span v-if="fastLinkConvite.loading" class="text-lowercase"> Carregando token...</span>
                                              <a v-else-if="fastLinkConvite.token" class="text-lowercase" :href="fastLinkConvite.url" target="_blank"> {{fastLinkConvite.url}} </a>
                                            </p>
                                        </div>                                                                                             
                                      </div>
                                      <div
                                        v-else
                                        class="text-xs font-weight-bold text-secondary text-uppercase mb-1"
                                      >  
                                        <p>Convite pendente: <span class="text-danger"> Não </span></p> 
                                      </div>
                                    </div>
                                    <div class="col-auto">
                                      <div
                                        v-if="fastUsuarioEditar.ativo == 'S'"
                                        class="h5 mb-0 font-weight-bold text-success"
                                      >
                                        <span
                                          v-if="fastUsuarioEditar.status == 'A'"
                                          class="btn btn-success"
                                        >Ativado</span>  
                                        <span
                                          v-else-if="fastUsuarioEditar.status == 'B'"
                                          class="btn btn-danger"
                                        >Conta bloqueada</span>  
                                        <span
                                          v-else-if="fastUsuarioEditar.status == 'I'"
                                          class="btn btn-danger"
                                        >Conta inativa</span>  
                                      </div>
                                      <div
                                        v-else
                                        class="h5 mb-0 font-weight-bold text-danger"
                                      >
                                        <span class="btn btn-danger">Desativado</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-4">
                            <div class="col-sm-12 col-md-12 col-lg-3 text-center">
                              <div
                                class="fast-cursor-pointer p-4 rounded border text-info"
                                @click="exibeModalEstatisticasUsuario()"
                              >
                                <b-icon-bar-chart-fill font-scale="3" />
                                <h6 class="mt-2">
                                  Cursos iniciados
                                </h6>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 text-center">
                              <div
                                class="fast-cursor-pointer p-4 rounded border text-warning"
                                @click="showModal('modalUsuarioReenviar')"
                              >
                                <b-icon-arrow-counterclockwise font-scale="3" />
                                <h6 class="mt-2">
                                  Reenviar convite
                                </h6>
                              </div>
                            </div>
                            <div
                              v-if="fastUsuarioEditar.ativo == 'S'"
                              class="col-sm-12 col-md-12 col-lg-3 text-center"
                            >
                              <div
                                class="fast-cursor-pointer p-4 rounded border text-danger"
                                @click="showModal('modalUsuarioDesativar')"
                              >
                                <b-icon-arrow-down-circle font-scale="3" />
                                <h6 class="mt-2">
                                  Desativar
                                </h6>
                              </div>
                            </div>
                            <div
                              v-else
                              class="col-sm-12 col-md-12 col-lg-3 text-center"
                            >
                              <div
                                class="fast-cursor-pointer p-4 rounded border text-success"
                                @click="showModal('modalUsuarioAtivar')"
                              >
                                <b-icon-arrow-up-circle font-scale="3" />
                                <h6 class="mt-2">
                                  Ativar
                                </h6>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 text-center">
                              <div
                                class="fast-cursor-pointer p-4 rounded border text-secondary"
                                style="cursor: not-allowed; background: rgb(154 154 154)"
                                @click.prevent="exibeToasty('Para excluir consulte um administrador da plataforma', 'error')"
                              >
                                <b-icon-trash font-scale="3" />
                                <h6 class="mt-2">
                                  Excluir
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-4">
                            <div class="col-sm-12 col-md-12 col-lg-3 text-center">
                              <div
                                class="fast-cursor-pointer p-4 rounded border text-info"
                                @click="exibeModalExibirCalendarioHistorico(anoBaseCalendario)"
                              >
                                <b-icon-calendar2-week font-scale="3" />
                                <h6 v-if="fastHistoricoDias.loading" class="mt-2">
                                  <b-icon icon="gear-fill" animation="spin"/> Carregando
                                </h6>
                                <h6 v-else class="mt-2">
                                  Histórico de eventos                                  
                                </h6>                                
                              </div>
                            </div>
                          </div>
                          <div class="row mt-4">
                            <div class="col-12 mt-4 text-center">
                              <h4>Cursos livres publicados ({{ fastMeusCursosFiltro.length }})</h4>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 mt-4 mb-1">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Filtrar curso por nome"
                                @keyup="filtraCursos"
                              >
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
                              <table class="table table-striped">
                                <thead class="thead-dark">
                                  <tr>
                                    <th scope="col" />
                                    <th scope="col">
                                      Curso
                                    </th>
                                    <th
                                      scope="col"
                                      class="text-center"
                                    >
                                      Ação
                                    </th>
                                  </tr>
                                </thead>
                                <tbody v-if="fastMeusCursosLoading">
                                  <tr>
                                    <td
                                      class="text-center"
                                      colspan="3"
                                    >
                                      Carregando cursos...
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody v-else-if="pageOfMeusCursos.length">
                                  <tr
                                    v-for="curso in pageOfMeusCursos"
                                    :key="curso.id_curso"
                                  >
                                    <td class="align-middle">
                                      #{{ curso.id_curso }}
                                    </td>
                                    <td class="align-middle">
                                      <a
                                        target="_blank"
                                        :href="
                                          '/plataforma/' +
                                            $route.params.id_plataforma +
                                            '/admin/curso/' +
                                            curso.id_curso
                                        "
                                      >
                                        <span v-if="curso.nome_curso">{{ curso.nome_curso }}</span>
                                        <span v-else>{{ curso.nome_curso_mae }}</span>
                                      </a>
                                    </td>
                                    <td class="align-middle text-center">
                                      <span
                                        v-if="!verificaUsuarioInseridoCurso(curso.id_curso).length"
                                        class="btn btn-sm btn-success text-white pt-0 pb-0"
                                        @click="insereUsuarioRestrito(fastUsuarioEditar.id_usuario, curso.id_curso)"
                                      >
                                        <small>+ Adicionar aluno ao curso</small>
                                      </span>
                                      <span
                                        v-else
                                        class="btn btn-sm btn-danger text-white pt-0 pb-0"
                                        @click="excluiUsuarioRestrito(fastUsuarioEditar.id_usuario, curso.id_curso)"
                                      >
                                        <small>- Remover aluno do curso</small>
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>                                
                                <tbody v-else>
                                  <tr>
                                    <td
                                      class="text-center"
                                      colspan="3"
                                    >
                                      Nenhuma curso encontrado
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="col-12 text-center mt-4">
                              <Pagination
                                :page-size="10"
                                :items="fastMeusCursosFiltro"
                                @changePage="pageOfMeusCursos = $event"
                              />
                            </div>
                          </div>
                          <div class="row mt-4">                                        
                            <div class="col-12 mt-2 mb-2">
                              <div class="form-group mb-0">
                                <button
                                  href="#"
                                  type="button"
                                  class="btn btn-secondary mr-1"
                                  @click="alteraFastNavegacao('exibirAlunos')"
                                >
                                  <small>Voltar</small>                                
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  v-if="getUrlParameter('secretaria')"
                  class="btn btn-red-hollow"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
                >
                  <b-icon-arrow-return-left />
                  Voltar para menu
                </a>
                <a
                  v-else
                  class="btn btn-red-hollow"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
                >
                  <b-icon-arrow-return-left />
                  Voltar para menu
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalTipoCadastro"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Escolha uma Opção</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalTipoCadastro')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-6">
                  <div
                    class="card fast-cursor-pointer"
                    @click.prevent="hideModal('modalTipoCadastro'); alteraFastNavegacao('convidarAlunos')"
                  >
                    <div class="card-body text-center">
                      <h5 class="card-title ">
                        Envio de emails
                      </h5>                            
                      <b-icon-card-checklist font-scale="4" />
                      <p class="card-text mt-2">
                        Convide os alunos através do email
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6">
                  <div
                    class="card fast-cursor-pointer"
                    @click.prevent="hideModal('modalTipoCadastro'); alteraFastNavegacao('cadastrarAluno')"
                  >
                    <div class="card-body text-center">
                      <h5 class="card-title">
                        Cadastro Individual
                      </h5>
                      <b-icon-file-earmark-person-fill font-scale="4" />
                      <p class="card-text mt-2">
                        Cadastre individualmente o aluno 
                      </p>                        
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalListaEmails"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Insira a lista de emails</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalListaEmails')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <textarea
                v-model="fastListaEmailsMassica"
                class="form-control"
                rows="10"
              />
              <small class="text-info">Insira um email embaixo do outro</small>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <button
                class="btn btn-sm btn-primary"
                @click="separaListaEmailsMassiva()"
              >
                <small>Inserir emails</small>                
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEstatisticasUsuario"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthLg"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4">
          <h4>Cursos iniciados</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEstatisticasUsuario')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mb-2">
              <button
                class="btn btn-sm btn-success pt-0 pb-0 mr-2"
                @click="geraRelatorioAlunoExcel(fastUsuarioCurso)"
              >
                <small
                  v-if="!fastCarregandoAcao"
                  class="text-white"
                >Gerar relatório de avaliações</small>
                <small v-else><b-icon
                  icon="gear-fill"
                  animation="spin"
                /> Carregando</small>
              </button>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
              <table class="table table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" />
                    <th scope="col">
                      Curso
                    </th>
                    <th scope="col">
                      Data de início
                    </th>
                    <th
                      scope="col"
                      class="text-center"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      class="text-center"
                    >
                      Realizou
                    </th>
                    <th
                      scope="col"
                      class="text-center"
                    >
                      Aulas realizadas
                    </th>
                  </tr>
                </thead>
                <tbody v-if="pageOfFastUsuarioCurso.length">
                  <tr
                    v-for="(curso, index) in pageOfFastUsuarioCurso"
                    :key="curso.id_usuario_curso"
                  >
                    <td class="align-middle">
                      {{ index + 1 }}
                    </td>
                    <td class="align-middle">
                      {{ curso.nome_curso }}
                    </td>
                    <td class="align-middle">
                      {{ new Date(curso.data_inicio_curso).toLocaleDateString() }}
                    </td>
                    <td class="align-middle text-center">
                      <small
                        v-if="curso.status == 'E'"
                        class="text-warning"
                      >Em execução</small>
                      <small
                        v-else-if="curso.status == 'F'"
                        class="text-success"
                      >Finalizado</small>
                    </td>
                    <td class="align-middle text-center">
                      <span
                        v-if="curso.count_curso"
                        class="btn btn-sm btn-success"
                      >
                        <small>{{ curso.count_curso }} veze(s)</small>
                      </span>
                      <span
                        v-else
                        class="btn btn-sm btn-danger"
                      >
                        <small />
                      </span>
                    </td>
                    <td class="align-middle text-center">
                      <small class="btn btn-sm btn-warning text-white pr-3 pl-3">{{ curso.aulas.length }}</small>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="fastUsuarioCursoLoading">
                  <tr>
                    <td
                      class="text-center"
                      colspan="6"
                    >
                      Carregando estatísticas...
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      class="text-center"
                      colspan="6"
                    >
                      Nenhuma estatísticas encontrada
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12 text-center mt-4">
              <Pagination
                :page-size="5"
                :items="fastUsuarioCurso"
                @changePage="pageOfFastUsuarioCurso = $event"
              />
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalUsuarioExcluir"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir usuário?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalUsuarioExcluir')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="confirmaUsuarioExcluir()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalUsuarioDesativar"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Desativar usuário?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalUsuarioDesativar')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="confirmaUsuarioDesativar()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalUsuarioAtivar"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Ativar usuário?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalUsuarioAtivar')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="confirmaUsuarioAtivar()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalUsuarioReenviar"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Reenviar convite?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalUsuarioReenviar')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click="confirmaUsuarioReenviar()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarEmailConvite"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4">
          <h4>Editar email</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarEmailConvite')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>

        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mt-2">
              <label class="text-secondary font-weight-bold">Nome</label>
              <input
                :value="fastEmailConvite.nome_email"
                class="form-control"
                type="text"
                readonly
              >
            </div>

            <div
              v-if="fastEmailConvite.email_destino == 'AD'"
              class="col-sm-12 col-md-12 col-lg-3 mt-2"
            >
              <label class="text-secondary font-weight-bold">Email para envio</label>
              <input
                v-model="fastEmailConvite.email_envio"
                class="form-control"
                type="text"
              >
            </div>
            <div
              v-if="fastEmailConvite.email_destino == 'AD'"
              class="col-sm-12 col-md-12 col-lg-3 mt-2"
            >
              <label class="text-secondary font-weight-bold">Email cópia</label>
              <input
                v-model="fastEmailConvite.email_copia"
                class="form-control"
                type="text"
              >
            </div>
            <div
              v-if="!fastEmailConvite.obrigatorio"
              class="col-sm-12 col-md-12 col-lg-12 mt-2"
            >
              <label class="text-secondary font-weight-bold">Habilitar</label>
              <div class="fd-app-curso-aulas mt-0">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div class="fd-app-curso-aulas-list-item shadow-none border-0 p-0">
                    <label class="switch mr-2">
                      <input
                        v-model="fastEmailConvite.ativo"
                        :checked="fastEmailConvite.ativo"
                        type="checkbox"
                      >
                      <span class="slider round" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <label class="text-secondary font-weight-bold">Descrição</label>
              <p class="m-0 text-secondary">
                {{ fastEmailConvite.descricao }}
              </p>
            </div>
            <div class="col-12 pt-2 pb-2">
              <div class="alert alert-info">
                <small
                  class="m-0 font-weight-bold text-secondary"
                >Você pode usar as seguintes palavras especiais no seu texto:</small>
                <table>
                  <tbody v-if="fastEmailConvite.variaveis_disponiveis.split(',').length">
                    <tr>
                      <td
                        v-for="(variavel, index) in fastEmailConvite.variaveis_disponiveis.split(',')"
                        :key="index"
                        class="pr-4"
                      >
                        <small class="m-0 text-secondary">
                          <span class="font-weight-bold">{{ variavel }}</span> 
                        </small>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td class="pr-4">
                        Nenhuma variável disponível
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-2">
                <small class="text-info">Corpo do email que será enviado, utilize o editor para inserir links, imagens e customizar o texto. Ou personalize o HTML do corpo do email clicando aqui: <button
                  class="btn btn-sm btn-primary pt-0 pb-0"
                  @click="showModal('modalEditarHTMLEmailConvite', 'modalEditarEmailConvite')"
                ><b-icon-code-slash /> Editar HTML</button></small>
              </div>              
              <quill-editor
                v-model="fastEmailConvite.html_email"
                :options="editorOption"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
              <div>
                <button
                  class="btn btn-primary"
                  @click="editarEmail(fastEmailConvite)"
                >
                  <small>Salvar alterações</small>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalEditarHTMLEmailConvite"
      :width="modalWidthMd"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-8 pl-4 pr-4">
          <h4>Editar HTML do email</h4>
        </div>
        <div class="col-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalEditarEmailConvite', 'modalEditarHTMLEmailConvite')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <textarea
            v-model="fastEmailConvite.html_email"
            class="form-control"
            rows="10"
          />
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <button
            class="btn btn-secondary mt-2 ml-2"
            @click.prevent="showModal('modalEditarEmailConvite', 'modalEditarHTMLEmailConvite')"
          >
            Voltar
          </button>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarEmailConfirmacao"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4">
          <h4>Editar email</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarEmailConfirmacao')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>

        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mt-2">
              <label class="text-secondary font-weight-bold">Nome</label>
              <input
                :value="fastEmailConfirmacao.nome_email"
                class="form-control"
                type="text"
                readonly
              >
            </div>

            <div
              v-if="fastEmailConfirmacao.email_destino == 'AD'"
              class="col-sm-12 col-md-12 col-lg-3 mt-2"
            >
              <label class="text-secondary font-weight-bold">Email para envio</label>
              <input
                v-model="fastEmailConfirmacao.email_envio"
                class="form-control"
                type="text"
              >
            </div>
            <div
              v-if="fastEmailConfirmacao.email_destino == 'AD'"
              class="col-sm-12 col-md-12 col-lg-3 mt-2"
            >
              <label class="text-secondary font-weight-bold">Email cópia</label>
              <input
                v-model="fastEmailConfirmacao.email_copia"
                class="form-control"
                type="text"
              >
            </div>
            <div
              v-if="!fastEmailConfirmacao.obrigatorio"
              class="col-sm-12 col-md-12 col-lg-12 mt-2"
            >
              <label class="text-secondary font-weight-bold">Habilitar</label>
              <div class="fd-app-curso-aulas mt-0">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div class="fd-app-curso-aulas-list-item shadow-none border-0 p-0">
                    <label class="switch mr-2">
                      <input
                        v-model="fastEmailConfirmacao.ativo"
                        :checked="fastEmailConfirmacao.ativo"
                        type="checkbox"
                      >
                      <span class="slider round" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <label class="text-secondary font-weight-bold">Descrição</label>
              <p class="m-0 text-secondary">
                {{ fastEmailConfirmacao.descricao }}
              </p>
            </div>
            <div class="col-12 pt-2 pb-2">
              <div class="alert alert-info">
                <small
                  class="m-0 font-weight-bold text-secondary"
                >Você pode usar as seguintes palavras especiais no seu texto:</small>
                <table>
                  <tbody v-if="fastEmailConfirmacao.variaveis_disponiveis.split(',').length">
                    <tr>
                      <td
                        v-for="(variavel, index) in fastEmailConfirmacao.variaveis_disponiveis.split(',')"
                        :key="index"
                        class="pr-4"
                      >
                        <small class="m-0 text-secondary">
                          <span class="font-weight-bold">{{ variavel }}</span> 
                        </small>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td class="pr-4">
                        Nenhuma variável disponível
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-2">
                <small class="text-info">Corpo do email que será enviado, utilize o editor para inserir links, imagens e customizar o texto. Ou personalize o HTML do corpo do email clicando aqui: <button
                  class="btn btn-sm btn-primary pt-0 pb-0"
                  @click="showModal('modalEditarHTMLEmailConfirmacao', 'modalEditarEmailConfirmacao')"
                ><b-icon-code-slash /> Editar HTML</button></small>
              </div>              
              <quill-editor
                v-model="fastEmailConfirmacao.html_email"
                :options="editorOption"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
              <div>
                <button
                  class="btn btn-primary"
                  @click="editarEmail(fastEmailConfirmacao)"
                >
                  <small>Salvar alterações</small>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalEditarHTMLEmailConfirmacao"
      :width="modalWidthMd"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-8 pl-4 pr-4">
          <h4>Editar HTML do email</h4>
        </div>
        <div class="col-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalEditarEmailConfirmacao', 'modalEditarHTMLEmailConfirmacao')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <textarea
            v-model="fastEmailConfirmacao.html_email"
            class="form-control"
            rows="10"
          />
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <button
            class="btn btn-secondary mt-2 ml-2"
            @click.prevent="showModal('modalEditarEmailConfirmacao', 'modalEditarHTMLEmailConfirmacao')"
          >
            Voltar
          </button>
        </div>
      </div>
    </modal>

    <modal
      name="modalExibirCalendarioHistorico"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthLg"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Histórico de eventos</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="fasCalendarioNavegacao.exibirCalendario ? hideModal('modalExibirCalendarioHistorico') : fasCalendarioNavegacao.exibirCalendario = true; fasCalendarioNavegacao.exibirDiaSelecionado = false;"
          >
            <b-icon-arrow-return-left /> Voltar
          </a>
        </div>
        <div class="col-12 p-4">
          <div v-if="fasCalendarioNavegacao.exibirCalendario" class="container mt-4">
            <fast-calendar :anoBase="anoBaseCalendario" :exibirFeriados="false" :exibirDescricaoDatas="false" :retornaDataSelecionada="diaCalendarioClicado" :eventosAluno="fastHistoricoDias.total" />
          </div>
          <div v-else-if="fasCalendarioNavegacao.exibirDiaSelecionado" class="container mt-4">
            <h1 class="text-center text-info">
              {{fastHistoricoDiaSelecionado.data_selecionada}} 
              <a
                class="fast-cursor-refresh"
                @click.prevent="diaCalendarioClicado({ date: fastHistoricoDiaSelecionado.data_selecionada})"
              >
                <b-icon-arrow-counterclockwise font-scale="1" />
              </a>
            </h1>
            <div class="row">
              <div class="col-12 fast-container-timeline-body">

                <div v-if="fastHistoricoDiaSelecionado.loading" class="text-center">
                  <b-icon icon="gear-fill" animation="spin" /> Carregando
                </div>
                <div v-else-if="fastHistoricoDiaSelecionado.total.length" class="fast-container-timeline">
                  <div v-for="(e, index) in fastHistoricoDiaSelecionado.total" :key="index" :class="index % 2 == 0 ? 'fast-container-timeline-item fast-container-timeline-item-left' : 'fast-container-timeline-item fast-container-timeline-item-right'">
                    <div class="fast-container-timeline-item-content text-secondary">
                      <b-card-group >
                        <b-card :header="formataDataHoraT(e.data_evento)">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 text-info">
                              <b><b-icon-info-circle /> {{e.descricao_evento}}</b>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div v-if="e.nome_plataforma" class="col-sm-12 col-md-12 col-lg-12">
                              <small>Plataforma - <b>{{e.nome_plataforma}}</b></small>
                            </div>
                            <div v-if="e.sigla_turma" class="col-sm-12 col-md-12 col-lg-12">
                              <small>Turma - <b>{{e.sigla_turma}}</b></small>
                            </div>
                            <div v-if="e.nome_curso_secretaria" class="col-sm-12 col-md-12 col-lg-12">
                              <small>Curso - <b>{{e.nome_curso_secretaria}}</b></small>
                            </div>
                            <div v-if="e.sigla_programa" class="col-sm-12 col-md-12 col-lg-12">
                              <small>Módulo {{e.sequencia_modulo}} - <b>{{e.sigla_programa}}</b></small>
                            </div>
                            <div v-if="e.nome_curso && e.nome_curso_secretaria" class="col-sm-12 col-md-12 col-lg-12">
                              <small>Unidade curricular - <b>{{e.nome_curso}}</b></small>
                            </div>
                            <div v-else-if="e.nome_curso && !e.nome_curso_secretaria" class="col-sm-12 col-md-12 col-lg-12">
                              <small>Curso livre - <b>{{e.nome_curso}}</b></small>
                            </div>
                            <div v-if="e.titulo_aula" class="col-sm-12 col-md-12 col-lg-12">
                              <small>Aula {{e.sequencia_aula}} - <b>{{e.titulo_aula}}</b></small>
                            </div>
                            <div v-if="e.tipo_avaliacao" class="col-sm-12 col-md-12 col-lg-12">
                              <small v-if="e.tipo_avaliacao == 'A'">Avaliação principal - <b>{{e.titulo_avaliacao}}</b></small>
                              <small v-else-if="e.tipo_avaliacao == 'R'">Recuperação - <b>{{e.titulo_avaliacao}}</b></small>
                            </div>                  
                          </div>
                          
                          <div class="row mt-2">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                              <button v-b-toggle="'collapse-useragent-' + e.id_secretaria_aluno_auditoria" class="btn btn-sm btn-primary pt-0 pb-0"><small>Detalhes técnicos</small></button>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                              <b-collapse :id="'collapse-useragent-' + e.id_secretaria_aluno_auditoria">
                                <b-card>
                                  <div class="row">
                                    <div v-if="e.tipo_dispositivo == 'D'" class="col-sm-12 col-md-12 col-lg-12">
                                      <small>Dispositivo: <b>Desktop</b></small>
                                    </div>
                                    <div v-else-if="e.tipo_dispositivo == 'M'" class="col-sm-12 col-md-12 col-lg-12">
                                      <small>Dispositivo: <b>Mobile</b></small>
                                    </div>
                                    <div v-if="e.user_agent" class="col-sm-12 col-md-12 col-lg-12">
                                      <small>Browser: <b>{{ detectaBrowser(e.user_agent) }}</b> </small> 
                                    </div>
                                    <div v-if="e.user_agent" class="col-sm-12 col-md-12 col-lg-12">
                                      <small>User-agent: <br/><b>{{e.user_agent}}</b></small>                        
                                    </div>
                                    <div v-if="e.endereco_ip" class="col-sm-12 col-md-12 col-lg-12">
                                      <small>Endereço IP: <a :href="'https://whatismyipaddress.com/ip/' + e.endereco_ip.trim()" target="_blank">{{ e.endereco_ip }}</a></small>                        
                                    </div>
                                  </div>
                                </b-card>
                              </b-collapse>
                            </div>   
                          </div>
                        </b-card>
                      </b-card-group>
                    </div>
                  </div>
                </div>
                <div v-else class="text-center">
                  Nenhum registro identificado
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="fasCalendarioNavegacao.exibirCalendario ? hideModal('modalExibirCalendarioHistorico') : fasCalendarioNavegacao.exibirCalendario = true; fasCalendarioNavegacao.exibirDiaSelecionado = false;"
              >
                <b-icon-arrow-return-left /> Voltar
              </a>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <b-modal id="modalExibirCalendarioHistorico" size="xl" scrollable title="Histórico de eventos" >
      <div v-if="fasCalendarioNavegacao.exibirCalendario">
        <fast-calendar :anoBase="anoBaseCalendario" :exibirFeriados="false" :exibirDescricaoDatas="false" :retornaDataSelecionada="diaCalendarioClicado" :eventosAluno="fastHistoricoDias.total" :idUsuarioAluno="fastUsuarioEditar.id_usuario" :retornaAnoSelecionado="anoCalendarioClicado"/>
      </div>
      <div v-else-if="fasCalendarioNavegacao.exibirDiaSelecionado">
        <h1 class="text-center text-info">
          {{fastHistoricoDiaSelecionado.data_selecionada}} 
          <a
            class="fast-cursor-refresh"
            @click.prevent="diaCalendarioClicado({ date: fastHistoricoDiaSelecionado.data_selecionada})"
          >
            <b-icon-arrow-counterclockwise font-scale="1" />
          </a>
        </h1>
        <div class="row">
          <div class="col-12 fast-container-timeline-body">

            <div v-if="fastHistoricoDiaSelecionado.loading" class="text-center">
              <b-icon icon="gear-fill" animation="spin" /> Carregando
            </div>
            <div v-else-if="fastHistoricoDiaSelecionado.total.length" class="fast-container-timeline">
              <div v-for="(e, index) in fastHistoricoDiaSelecionado.total" :key="index" :class="index % 2 == 0 ? 'fast-container-timeline-item fast-container-timeline-item-left' : 'fast-container-timeline-item fast-container-timeline-item-right'">
                <div class="fast-container-timeline-item-content text-secondary">
                  <b-card-group >
                    <b-card :header="formataDataHoraT(e.data_evento)">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 text-info">
                          <b><b-icon-info-circle /> {{e.descricao_evento}}</b>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div v-if="e.nome_plataforma" class="col-sm-12 col-md-12 col-lg-12">
                          <small>Plataforma - <b>{{e.nome_plataforma}}</b></small>
                        </div>
                        <div v-if="e.sigla_turma" class="col-sm-12 col-md-12 col-lg-12">
                          <small>Turma - <b>{{e.sigla_turma}}</b></small>
                        </div>
                        <div v-if="e.nome_curso_secretaria" class="col-sm-12 col-md-12 col-lg-12">
                          <small>Curso - <b>{{e.nome_curso_secretaria}}</b></small>
                        </div>
                        <div v-if="e.sigla_programa" class="col-sm-12 col-md-12 col-lg-12">
                          <small>Módulo {{e.sequencia_modulo}} - <b>{{e.sigla_programa}}</b></small>
                        </div>
                        <div v-if="e.nome_curso && e.nome_curso_secretaria" class="col-sm-12 col-md-12 col-lg-12">
                          <small>Unidade curricular - <b>{{e.nome_curso}}</b></small>
                        </div>
                        <div v-else-if="e.nome_curso && !e.nome_curso_secretaria" class="col-sm-12 col-md-12 col-lg-12">
                          <small>Curso livre - <b>{{e.nome_curso}}</b></small>
                        </div>
                        <div v-if="e.titulo_aula" class="col-sm-12 col-md-12 col-lg-12">
                          <small>Aula {{e.sequencia_aula}} - <b>{{e.titulo_aula}}</b></small>
                        </div>
                        <div v-if="e.tipo_avaliacao" class="col-sm-12 col-md-12 col-lg-12">
                          <small v-if="e.tipo_avaliacao == 'A'">Avaliação principal - <b>{{e.titulo_avaliacao}}</b></small>
                          <small v-else-if="e.tipo_avaliacao == 'R'">Recuperação - <b>{{e.titulo_avaliacao}}</b></small>
                        </div>                  
                      </div>
                      
                      <div class="row mt-2">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <button v-b-toggle="'collapse-useragent-' + e.id_secretaria_aluno_auditoria" class="btn btn-sm btn-primary pt-0 pb-0"><small>Detalhes técnicos</small></button>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                          <b-collapse :id="'collapse-useragent-' + e.id_secretaria_aluno_auditoria">
                            <b-card>
                              <div class="row">
                                <div v-if="e.tipo_dispositivo == 'D'" class="col-sm-12 col-md-12 col-lg-12">
                                  <small>Dispositivo: <b>Desktop</b></small>
                                </div>
                                <div v-else-if="e.tipo_dispositivo == 'M'" class="col-sm-12 col-md-12 col-lg-12">
                                  <small>Dispositivo: <b>Mobile</b></small>
                                </div>
                                <div v-if="e.user_agent" class="col-sm-12 col-md-12 col-lg-12">
                                  <small>Browser: <b>{{ detectaBrowser(e.user_agent) }}</b> </small> 
                                </div>
                                <div v-if="e.user_agent" class="col-sm-12 col-md-12 col-lg-12">
                                  <small>User-agent: <br/><b>{{e.user_agent}}</b></small>                        
                                </div>
                                <div v-if="e.endereco_ip" class="col-sm-12 col-md-12 col-lg-12">
                                  <small>Endereço IP: <a :href="'https://whatismyipaddress.com/ip/' + e.endereco_ip.trim()" target="_blank">{{ e.endereco_ip }}</a></small>                        
                                </div>
                              </div>
                            </b-card>
                          </b-collapse>
                        </div>   
                      </div>
                    </b-card>
                  </b-card-group>
                </div>
              </div>
            </div>
            <div v-else class="text-center">
              Nenhum registro identificado
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button v-if="!fasCalendarioNavegacao.exibirCalendario" size="sm" variant="primary" @click="fasCalendarioNavegacao.exibirCalendario = true; fasCalendarioNavegacao.exibirDiaSelecionado = false;">
            <b-icon-arrow-return-left /> Calendário
        </b-button>
        <b-button size="sm" variant="secondary" @click="hide('forget')">
            Fechar <b-icon-x-circle-fill />
        </b-button>
      </template>
    </b-modal>

    <!-- Notificações -->
    <notifications
      group="foo"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import FastCalendar from "../components/FastSecretariaCalendario";

export default {
  name: "HomeInternoAdminAlunos",
  components: {
    Pagination, quillEditor, FastCalendar
  },
  mixins: [methods],
  data: function() {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      urlFastEAD: settings.endFastEad,
      // Navegação entre paginas
      fastNavegacao: {
        exibirAlunos: true,
        convidarAlunos: false,
        cadastrarAluno: false,
        alterarAluno: false,
      },
      // Lista de alunos
      fastFiltroNomeEmail: "",
      fastFiltroMatriculaAtiva: "",
      fastFiltroDataInicial: "",
      fastFiltroDataFinal: "",
      fastAlunosTotal: [],
      fastAlunosFiltro: [],
      fastAlunosPaginacao: [],
      fastAlunosLoading: [],
      // Convite por email
      modelConviteUsuario: {
        fast_plataforma_usuario: {
          id_plataforma: this.$route.params.id_plataforma,
          administrador: "",
          edita_cursos: "",
          edita_usuarios: "",
          edita_professores: "",
          edita_plataforma: "",
          edita_matriz: "",
          edita_financeiro: "",
          edita_alunos: "",
          edita_admins: "",
          professor: "",
          aluno: "",
          tesouraria: "",
          secretario: "",
          responsavel: ""
        },
        fast_usuario: {
          email: ""
        },
        fast_plataforma_professor: {
          first_name: "",
          last_name: "",
          licenciatura: "",
          image: ""
        }
      },
      fastEmailsConvite: [],
      fastEmail: {
        id_usuario: "",
        id_pessoa: 0,
        email: "",
        status: "Aguardando...",
        classe: "text-info",
        code: "",
        first_name: "Nome",
        last_name: "não identificado",
        image: "",
        email: "",
        administrador: "",
        professor: "",
        aluno: "",
        tesouraria: "",
        secretario: "",
        responsavel: ""
      },
      fastBtnConvidar: true,
      fastListaEmailsMassica: "",
      // Cadastro individual
      modelCadastroUsuario: {
        fast_plataforma_usuario: {
          id_plataforma: this.$route.params.id_plataforma,
          administrador: "",
          edita_cursos: "",
          edita_usuarios: "",
          edita_professores: "",
          edita_plataforma: "",
          edita_matriz: "",
          edita_financeiro: "",
          edita_alunos: "",
          edita_admins: "",
          professor: "",
          aluno: "",
          tesouraria: "",
          secretario: "",
          responsavel: ""
        },
        fast_usuario: {
          id_usuario: 0,
          first_name: "",
          last_name: "",
          email: ""
        },
        fast_pessoa: {
          cpf_cnpj: "",
          datanascimento_abertura: null,
          banco: "",
          codigo_banco: "",
          sexo: "",
        },
        fast_pessoa_endereco: {
          tipo: "",
          uf: "",
          cep: "",
          logradouro: "",
          bairro: "",
          cidade: "",
          complemento: "",
          numero: ""
        },
        fast_pessoa_telefone: {
          tipo: 1,
          telefone: "",
          ramal: ""
        },
        fast_usuario_parametro: {
          image: ""
        },
        fast_plataforma_professor: {
          first_name: "",
          last_name: "",
          licenciatura: "",
          image: ""
        },
        envia_email: true
      },
      fastEmailCadastro: {
        id_usuario: "",
        id_pessoa: 0,
        email: "",
        status: "",
        classe: "text-info",
        code: "",
        first_name: "Nome",
        last_name: "não identificado",
        image: "",
        administrador: "",
        professor: "",
        aluno: "",
        tesouraria: "",
        secretario: "",
        responsavel: "",
        btnCadastrar: true
      },
      // Editar usuário
      fastUsuarioEditar: {
        id_usuario: "",
        first_name: "",
        last_name: "",
        image: "",
        ativo: "",
        aceito: "",
        data_convite_envio: "",
        data_aceite: null,
        token: "",
        administrador: "",
        edita_cursos: "",
        edita_usuarios: "",
        edita_professores: "",
        edita_plataforma: "",
        edita_matriz: "",
        edita_financeiro: "",
        edita_alunos: "",
        edita_admins: "",
        professor: "",
        aluno: "",
        tesouraria: "",
        secretario: "",
        responsavel: ""
      },
      // Estatiscas do usuario
      fastUsuarioCurso: [],
      fastUsuarioCursoLoading: true,
      pageOfFastUsuarioCurso: [],
      fastCarregandoAcao: false,
      // Cursos
      fastMeusCursos: [],
      pageOfMeusCursos: [],
      fastMeusCursosFiltro: [],
      fastMeusCursosLoading: true,
      usuariosRestritos: [],
      // Emails
      fastEmailConvite: {
        assunto: "",
        ativo: "",
        customizado: "",
        data_customizacao: "",
        data_restauracao_padrao: "",
        email_copia: "",
        email_destino: "",
        email_envio: "",
        html_email: "",
        html_email_padrao: "",
        id_email: "",
        id_email_restauracao: "",
        id_plataforma: 0,
        id_usuario_customizacao: "",
        id_usuario_restauracao: "",
        nome_email: "",
        descricao: "",
        obrigatorio: false,
        modulo: "",
        variaveis_disponiveis: ""
      },
      fastEmailConfirmacao: {
        assunto: "",
        ativo: "",
        customizado: "",
        data_customizacao: "",
        data_restauracao_padrao: "",
        email_copia: "",
        email_destino: "",
        email_envio: "",
        html_email: "",
        html_email_padrao: "",
        id_email: "",
        id_email_restauracao: "",
        id_plataforma: 0,
        id_usuario_customizacao: "",
        id_usuario_restauracao: "",
        nome_email: "",
        descricao: "",
        obrigatorio: false,
        modulo: "",
        variaveis_disponiveis: ""
      },
       // Editor de texto
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }, { align: [] }, "image"],
          ],
        },
      },
      // Link do convite para admin do fast
      fastLinkConvite: {
        url: "",
        token: "",
        loading: false
      },
      // Calendário histórico
      anoBaseCalendario: new Date().getFullYear(),
      fasCalendarioNavegacao: {
        exibirCalendario: true,
        exibirDiaSelecionado: false
      },
      fastHistoricoDias: {
        total: [],
        loading: false
      },
      fastHistoricoDiaSelecionado: {
        total: [],
        filtro: [],
        paginacao: [],
        data_selecionada: "",
        loading: true
      },
    };
  },
  mounted: function() {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          this.getUsuariosPlataforma(this.$route.params.id_plataforma, 'alunos')  
          this.getFastCursos(this.$route.params.id_plataforma)  
          this.getFastPlataformaEmail(this.$route.params.id_plataforma, 'Convite para aluno', 'Aluno aceitou o convite')
          this.$store.state.fastCarregando = false;

        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    // Lista de usuários
    async getUsuariosPlataforma(id_plataforma, perfil){
      this.fastAlunosLoading = true
      this.fastFiltroNomeEmail = ""
      this.fastFiltroMatriculaAtiva = ""
      this.fastFiltroDataInicial = ""
      this.fastFiltroDataFinal = ""
      let param = `id_plataforma=${id_plataforma}&administrador=N&professor=N&aluno=N&tesouraria=N&secretario=N&responsavel=N`
      switch (perfil) {
        case 'administradores':
          param = `id_plataforma=${id_plataforma}&administrador=S&professor=&aluno=&tesouraria=&secretario=&responsavel=`
          break;
        case 'professores':
          param = `id_plataforma=${id_plataforma}&administrador=&professor=S&aluno=&tesouraria=&secretario=&responsavel=`
          break;
        case 'alunos':
          param = `id_plataforma=${id_plataforma}&administrador=&professor=&aluno=S&tesouraria=&secretario=&responsavel=`
          break;
        case 'tesouraria':
          param = `id_plataforma=${id_plataforma}&administrador=&professor=&aluno=&tesouraria=S&secretario=&responsavel=`
          break;
        case 'secretario':
          param = `id_plataforma=${id_plataforma}&administrador=&professor=&aluno=&tesouraria=&secretario=S&responsavel=`
          break;
        case 'responsavel':
          param = `id_plataforma=${id_plataforma}&administrador=&professor=&aluno=&tesouraria=&secretario=&responsavel=S`
          break;
        default:
          break;
      }
      this.promiseGetFastApi("api/fast_plataforma_usuario/lista_usuarios_plataforma", param).then(obj => {
        console.log("getUsuariosPlataforma", obj)
        if (obj.length) {
          this.fastAlunosTotal = obj
          this.fastAlunosFiltro = obj
        }
        else {
          this.fastAlunosTotal = []
          this.fastAlunosFiltro = []
        }          
        this.fastAlunosLoading = false  
      }).catch(e => {         
        this.exibeToasty("Erro ao buscar alunos", "error");
        this.fastAlunosLoading = false
      })
    },
    filtraAlunos(){
      this.fastAlunosFiltro = this.fastAlunosTotal.filter((e) => {
        // nomeComposto é usado na verificação de first_name + last_name que pode ser diferente do nome_usuario
        const nomeComposto = (e.first_name+" "+e.last_name).toLowerCase().indexOf(this.fastFiltroNomeEmail.toLowerCase())
        const nomeFiltrado = nomeComposto != "-1" || e.nome_usuario.toLowerCase().indexOf(this.fastFiltroNomeEmail.toLowerCase()) != "-1" || e.first_name.toLowerCase().indexOf(this.fastFiltroNomeEmail.toLowerCase()) != "-1" || e.last_name.toLowerCase().indexOf(this.fastFiltroNomeEmail.toLowerCase()) != "-1"|| e.email.toLowerCase().indexOf(this.fastFiltroNomeEmail.toLowerCase()) != "-1";
        const dataFormatada = new Date(e.data_convite_envio).toISOString().split('T')[0]
        const dataFiltradaInicial = dataFormatada >= this.fastFiltroDataInicial
        const dataFiltradaFinal = dataFormatada <= this.fastFiltroDataFinal
        const dataFiltrada = dataFiltradaInicial && dataFiltradaFinal
        // utiliza os três filtros
        if (this.fastFiltroNomeEmail && this.fastFiltroMatriculaAtiva && this.fastFiltroDataInicial && this.fastFiltroDataFinal) {
          if (this.fastFiltroMatriculaAtiva == "true") {
            return nomeFiltrado && e.ativo == "S" && dataFiltrada;
          } else if (this.fastFiltroMatriculaAtiva == "false") {
            return nomeFiltrado && e.ativo == "N" && dataFiltrada;
          }
        // utiliza filtros de nome, matrícula ativa e data inicial
        } else if (this.fastFiltroNomeEmail && this.fastFiltroMatriculaAtiva && this.fastFiltroDataInicial) {
          if (this.fastFiltroMatriculaAtiva == "true") {
            return nomeFiltrado && e.ativo == "S" && dataFiltradaInicial;
          } else if (this.fastFiltroMatriculaAtiva == "false") {
            return nomeFiltrado && e.ativo == "N" && dataFiltradaInicial;
          }
        // utiliza filtros de nome, matrícula ativa e data final
        } else if (this.fastFiltroNomeEmail && this.fastFiltroMatriculaAtiva && this.fastFiltroDataFinal) {
          if (this.fastFiltroMatriculaAtiva == "true") {
            return nomeFiltrado && e.ativo == "S" && dataFiltradaFinal;
          } else if (this.fastFiltroMatriculaAtiva == "false") {
            return nomeFiltrado && e.ativo == "N" && dataFiltradaFinal;
          }
        // utiliza os filtros de nome e matrícula ativa
        } else if (this.fastFiltroNomeEmail && this.fastFiltroMatriculaAtiva){
          if (this.fastFiltroMatriculaAtiva == "true") {
            return nomeFiltrado && e.ativo == "S";
          } else if (this.fastFiltroMatriculaAtiva == "false") {
            return nomeFiltrado && e.ativo == "N";
          }
        // utiliza os filtros de nome e data
        } else if (this.fastFiltroNomeEmail && this.fastFiltroDataInicial && this.fastFiltroDataFinal) {
          return nomeFiltrado && dataFiltrada;
        // utiliza os filtros de nome e data inicial
        } else if (this.fastFiltroNomeEmail && this.fastFiltroDataInicial) {
          return nomeFiltrado && dataFiltradaInicial;
        // utiliza os filtros de nome e data final
        } else if (this.fastFiltroNomeEmail && this.fastFiltroDataFinal) {
          return nomeFiltrado && dataFiltradaFinal;
        // utiliza os filtros de matrícula ativa e data
        } else if (this.fastFiltroMatriculaAtiva && this.fastFiltroDataInicial && this.fastFiltroDataFinal){
          if (this.fastFiltroMatriculaAtiva == "true") {
            return dataFiltrada && e.ativo == "S";
          } else if (this.fastFiltroMatriculaAtiva == "false") {
            return dataFiltrada && e.ativo == "N";
          }
        // utiliza o filtro de matrícula ativa e data inicial
        } else if (this.fastFiltroMatriculaAtiva && this.fastFiltroDataInicial){
          if (this.fastFiltroMatriculaAtiva == "true") {
            return dataFiltradaInicial && e.ativo == "S";
          } else if (this.fastFiltroMatriculaAtiva == "false") {
            return dataFiltradaInicial && e.ativo == "N";
          }
        // utiliza o filtro de matrícula ativa e data final
        } else if (this.fastFiltroMatriculaAtiva && this.fastFiltroDataFinal){
          if (this.fastFiltroMatriculaAtiva == "true") {
            return dataFiltradaFinal && e.ativo == "S";
          } else if (this.fastFiltroMatriculaAtiva == "false") {
            return dataFiltradaFinal && e.ativo == "N";
          }
        // utiliza o filtro de matrícula ativa
        } else if (this.fastFiltroMatriculaAtiva) {
          if (this.fastFiltroMatriculaAtiva == "true") {
            return e.ativo == "S";
          } else if (this.fastFiltroMatriculaAtiva == "false") {
            return e.ativo == "N";
          }
        // utiliza o filtro de nome/email
        } else if (this.fastFiltroNomeEmail) {
          return nomeFiltrado;
        // utiliza o filtro de data completa
        } else if (this.fastFiltroDataInicial && this.fastFiltroDataFinal) {
          return dataFiltrada;
        // utiliza o filtro de data inicial
        } else if (this.fastFiltroDataInicial) {
          return dataFiltradaInicial;
        // utiliza o filtro de data final
        } else if (this.fastFiltroDataFinal) {
          return dataFiltradaFinal;
        // zera a lista filtrada
        } else {
          return this.fastAlunosFiltro = this.fastAlunosTotal;
        }
      });        
    },
    // Convite através de email
    adicionaEmailLista(){
      let email = this.fastEmail.email.trim()
      if (this.validateEmail(email)) {
        let emailInserido = this.fastEmailsConvite.filter(e => e.email == email)
        if (!emailInserido.length) {
          this.fastEmailsConvite.push({ id_usuario: "", id_pessoa: 0, email: email, status: "Adicionado", classe: "text-success", code: "", first_name: "Nome", last_name: "não identificado", image: "" })
          this.fastEmail.id_usuario = ""
          this.fastEmail.id_pessoa = ""
          this.fastEmail.email = ""
          this.fastEmail.status = "Aguardando..."
          this.fastEmail.classe = "text-info"
          this.fastEmail.code = ""
          this.fastEmail.first_name = "Nome"
          this.fastEmail.last_name = "não identificado"
          this.fastEmail.image = ""
          this.fastEmail.administrador = ""
          this.fastEmail.professor = ""
          this.fastEmail.aluno = ""
          this.fastEmail.tesouraria = ""
          this.fastEmail.secretario = ""
          this.fastEmail.responsavel = ""
        } else {
          this.exibeToasty("Email já consta na lista", "error");
        }        
        
      } else {
        this.exibeToasty("Insira um email válido", "error");
      }      
    },
    removeEmailLista(email){
      this.fastEmailsConvite = this.fastEmailsConvite.filter(function(e){ 
          return e.email != email; 
      });
    },
    convidarUsuarios(){
      this.fastBtnConvidar = false
      this.fastEmailsConvite.forEach(e => {
        e.status = "Verificando email na base..."
        this.promiseGetFastApi("api/fast_plataforma_usuario/usuario_check", `email=${e.email}&id_plataforma=${this.$route.params.id_plataforma}`).then(res => {
          const retorno = JSON.parse(res)
          if (retorno.error){
            e.status = retorno.description
            e.classe = "text-danger"
          } else {
            e.status = retorno.description
            e.classe = "text-success"
            e.code = retorno.code
          }      

          switch (retorno.code) {
            case "email_on_plataform":
              e.status = "Não é possível convidar pois o usuário já está associado à plataforma"              
              e.classe = "text-danger"
              e.first_name = retorno.first_name
              e.last_name = retorno.last_name
              e.image = retorno.image
              e.id_usuario = retorno.id_usuario
              e.id_pessoa = retorno.id_pessoa
              break;
            case "email_on_system":
              e.status = "Email já cadastrado no sistema. Confirme o convite"
              e.classe = "text-warning"
              e.first_name = retorno.first_name
              e.last_name = retorno.last_name
              e.image = retorno.image
              e.id_usuario = retorno.id_usuario
              e.id_pessoa = retorno.id_pessoa
              break;
            case "email_unknown":
              e.status = "Cadastrando novo usuário. Aguarde..."
              e.classe = "text-success"
              this.cadastrarUsuario('alunos', e)
              break;          
            default:
              e.status = "Código de retorno não identificado"
              e.classe = "text-danger"
              break;
          }   

        }).catch(e => {         
          e.status = "Erro ao verificar email"
          e.classe = "text-danger"
        })
      })
    },
    cancelaConviteUsuarios(){
      this.fastBtnConvidar = true; 
      this.fastEmailsConvite = []
      this.fastEmailCadastro = {
        id_usuario: "",
        id_pessoa: 0,
        email: "",
        status: "",
        classe: "text-info",
        code: "",
        first_name: "Nome",
        last_name: "não identificado",
        image: "",
        btnCadastrar: true
      };
      this.modelCadastroUsuario = {
        fast_plataforma_usuario: {
          id_plataforma: this.$route.params.id_plataforma,
          administrador: "",
          edita_cursos: "",
          edita_usuarios: "",
          edita_professores: "",
          edita_plataforma: "",
          edita_matriz: "",
          edita_financeiro: "",
          edita_alunos: "",
          edita_admins: "",
          professor: "",
          aluno: "",
          tesouraria: "",
          secretario: "",
          responsavel: ""
        },
        fast_usuario: {
          first_name: "",
          last_name: "",
          email: ""
        },
        fast_pessoa: {
          cpf_cnpj: "",
          datanascimento_abertura: null,
          banco: "",
          codigo_banco: "",
          sexo: "",
        },
        fast_pessoa_endereco: {
          tipo: "",
          uf: "",
          cep: "",
          logradouro: "",
          bairro: "",
          cidade: "",
          complemento: "",
          numero: ""
        },
        fast_pessoa_telefone: {
          tipo: 1,
          telefone: "",
          ramal: ""
        },
        fast_usuario_parametro: {
          image: ""
        },
        fast_plataforma_professor: {
          first_name: "",
          last_name: "",
          licenciatura: "",
          image: ""
        },
        envia_email: true
      };
      this.alteraFastNavegacao('exibirAlunos');       
    },
    separaListaEmailsMassiva() {
      if (this.fastListaEmailsMassica.length) {
        let listaEmails = this.fastListaEmailsMassica.split("\n");
        listaEmails.forEach((e) => e = e.trim());
        if (listaEmails.length) {
          listaEmails.forEach(email => {
            console.log(this.validateEmail(email))
            let emailInserido = this.fastEmailsConvite.filter(e => e.email == email)            
            if (!emailInserido.length) {
              if (this.validateEmail(email)) this.fastEmailsConvite.push({ email: email, status: "Adicionado", classe: "text-success", code: "", first_name: "Nome", last_name: "não identificado", image: "" })
              else
                this.exibeToasty(`O email ${email} é inválido`, "error");
            } else {
              this.exibeToasty(`O email ${email} já foi inserido`, "error");
            }          
          })
        }
        this.hideModal('modalListaEmails')
        this.fastListaEmailsMassica = ""
      } else {
        this.exibeToasty("Insira ao menos um email válido", "error");
      }
    },
    cadastrarUsuario(perfil, fastEmail){
      fastEmail.status = "Cadastrando usuário. Aguarde..."
      switch (perfil) {
        case 'administradores':          
          
          break;
        case 'professores':
          
          break;
        case 'alunos':
          this.modelConviteUsuario = {
            fast_plataforma_usuario: {
              id_plataforma: this.$route.params.id_plataforma,
              administrador: "N",
              edita_cursos: "N",
              edita_usuarios: "N",
              edita_professores: "N",
              edita_plataforma: "N",
              edita_matriz: "N",
              edita_financeiro: "N",
              edita_alunos: "N",
              edita_admins: "N",
              professor: "N",
              aluno: "S",
              tesouraria: "N",
              secretario: "N",
              responsavel: "N"
            },
            fast_usuario: {
              email: fastEmail.email
            },
            fast_plataforma_professor: {
              first_name: "",
              last_name: "",
              licenciatura: "",
              image: ""
            }
          }

          this.promisePostFastApi(this.modelConviteUsuario, "api/fast_plataforma_usuario/convida_usuario").then(res => {
            console.log("cadastrarUsuario", res)
            const retorno = JSON.parse(res)
            if (retorno.error) {
              fastEmail.status = retorno.description
              fastEmail.classe = "text-danger"
            } else {
               if (retorno.code == "OK") {
                 fastEmail.status = "Convite enviado com sucesso"
                 fastEmail.code = "OK"
                 fastEmail.classe = "text-success"                 
               } else {
                 fastEmail.status = "Erro desconhecido. Contate o administrador do sistema."
                fastEmail.classe = "text-danger"
               }
            }
          }).catch(e => {         
            fastEmail.status = "Erro ao convidar aluno"
            fastEmail.classe = "text-danger"
          })
          
          break;
        case 'tesouraria':
          
          break;
        case 'secretario':
          
          break;
        case 'responsavel':
          
          break;
        default:
          break;
      }
    },
    // Cadastro individual
    verificaCadastrarUsuarioCompleto(perfil){
      let erros = []
      if (!this.modelCadastroUsuario.fast_usuario.first_name) erros.push("O nome é obrigatório")
      if (!this.modelCadastroUsuario.fast_usuario.last_name) erros.push("O sobrenome é obrigatório")
      if (!this.modelCadastroUsuario.fast_usuario.email) erros.push("O email é obrigatório")

      switch (perfil) {
        case 'administradores':          
          
          break;
        case 'professores':
          
          break;
        case 'alunos':
          this.modelCadastroUsuario.fast_plataforma_usuario.administrador = "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.edita_cursos = "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.edita_usuarios = "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.edita_professores = "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.edita_plataforma = "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.edita_matriz = "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.edita_financeiro = "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.edita_alunos = "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.edita_admins = "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.professor = "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.aluno = "S"
          this.modelCadastroUsuario.fast_plataforma_usuario.tesouraria = "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.secretario = "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.responsavel = "N"
          break;
        case 'tesouraria':
          
          break;
        case 'secretario':
          
          break;
        case 'responsavel':
          
          break;
        default:
          break;
      }

      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        this.fastEmailCadastro.btnCadastrar = false

        this.fastEmailCadastro.status = "Verificando email na base..."
        this.promiseGetFastApi("api/fast_plataforma_usuario/usuario_check", `email=${this.modelCadastroUsuario.fast_usuario.email}&id_plataforma=${this.$route.params.id_plataforma}`).then(res => {
          const retorno = JSON.parse(res)
          if (retorno.error){
            this.exibeToasty(retorno.description, "error")
          } else {
            this.fastEmailCadastro.code = retorno.code

            switch (retorno.code) {
            case "email_on_plataform":
              this.fastEmailCadastro.status = "Não é possível convidar pois o usuário já está associado à plataforma"              
              this.fastEmailCadastro.classe = "text-danger"
              this.fastEmailCadastro.first_name = retorno.first_name
              this.fastEmailCadastro.last_name = retorno.last_name
              this.fastEmailCadastro.image = retorno.image
              this.fastEmailCadastro.id_usuario = retorno.id_usuario
              this.fastEmailCadastro.id_pessoa = retorno.id_pessoa              
              this.fastEmailCadastro.email = retorno.email
              this.fastEmailCadastro.administrador = retorno.administrador
              this.fastEmailCadastro.professor = retorno.professor
              this.fastEmailCadastro.aluno = retorno.aluno
              this.fastEmailCadastro.tesouraria = retorno.tesouraria
              this.fastEmailCadastro.secretario = retorno.secretario
              this.fastEmailCadastro.responsavel = retorno.responsavel
              break;
            case "email_on_system":
              this.fastEmailCadastro.status = "Email já cadastrado no sistema. Confirme o convite"
              this.fastEmailCadastro.classe = "text-warning"
              this.fastEmailCadastro.first_name = retorno.first_name
              this.fastEmailCadastro.last_name = retorno.last_name
              this.fastEmailCadastro.image = retorno.image
              this.fastEmailCadastro.id_usuario = retorno.id_usuario
              this.fastEmailCadastro.email = retorno.email
              break;
            case "email_unknown":
              this.fastEmailCadastro.status = "Cadastrando novo usuário. Aguarde..."
              this.fastEmailCadastro.classe = "text-success"
              this.cadastrarUsuarioCompleto()
              break;          
            default:
              e.status = "Código de retorno não identificado"
              e.classe = "text-danger"
              break;
          }   
          }  

        }).catch(e => {         
          e.status = "Erro ao verificar email"
          e.classe = "text-danger"
        })

        
      }
    },
    cadastrarUsuarioCompleto(){
      this.promisePostFastApi(this.modelCadastroUsuario, "api/fast_plataforma_usuario/cadastro_usuario").then(res => {
        console.log("cadastrarUsuarioCompleto", res)
        const retorno = JSON.parse(res)
        if (retorno.error) {
          this.exibeToasty(e.description, "error");
          this.fastEmailCadastro.btnCadastrar = true
        } else {
          if (retorno.code == "OK") {
            this.getUsuariosPlataforma(this.$route.params.id_plataforma, 'alunos') 
            this.exibeToasty("Usuário cadastrado com sucesso", "success");
            this.cancelaConviteUsuarios('alunos')
            this.fastEmailCadastro = {
              id_usuario: 0,
              id_pessoa: 0,
              email: "",
              status: "",
              classe: "text-info",
              code: "",
              first_name: "Nome",
              last_name: "não identificado",
              image: "",
              btnCadastrar: true
            }
            this.modelCadastroUsuario = {
              fast_plataforma_usuario: {
                id_plataforma: this.$route.params.id_plataforma,
                administrador: "",
                edita_cursos: "",
                edita_usuarios: "",
                edita_professores: "",
                edita_plataforma: "",
                edita_matriz: "",
                edita_financeiro: "",
                edita_alunos: "",
                edita_admins: "",
                professor: "",
                aluno: "",
                tesouraria: "",
                secretario: "",
                responsavel: ""
              },
              fast_usuario: {
                first_name: "",
                last_name: "",
                email: ""
              },
              fast_pessoa: {
                cpf_cnpj: "",
                datanascimento_abertura: null,
                banco: "",
                codigo_banco: "",
                sexo: "",
              },
              fast_pessoa_endereco: {
                tipo: "",
                uf: "",
                cep: "",
                logradouro: "",
                bairro: "",
                cidade: "",
                complemento: "",
                numero: ""
              },
              fast_pessoa_telefone: {
                tipo: "",
                telefone: "",
                ramal: ""
              },
              fast_usuario_parametro: {
                image: ""
              },
              fast_plataforma_professor: {
                first_name: "",
                last_name: "",
                licenciatura: "",
                image: ""
              },
              envia_email: true
            }
          }
        }
      }).catch(e => {         
        this.exibeToasty("Erro ao cadastrar usuário", "error");
        this.fastEmailCadastro.btnCadastrar = true
      })
    },
    // Editar usuário
    exibeModalEditarUsuario(usuario){
      this.fastUsuarioEditar = usuario
      
      this.alteraFastNavegacao('editarAluno')
      
      this.getUsuariosRestritos(this.fastUsuarioEditar.id_usuario)
      .then((cursos) => {
        this.usuariosRestritos = cursos;
      })
      .catch((e) => {
        console.log("getUsuariosRestritos = ERRO", e);
      });
      
      this.fastLinkConvite.loading = true
      this.fastLinkConvite.token = ""
      this.fastLinkConvite.url = ""
      this.promiseGetFastApi("api/fast_plataforma_usuario/lista_token_admin", `id_plataforma=${this.$route.params.id_plataforma}&id_usuario=${this.fastUsuarioEditar.id_usuario}`)
      .then(res => {
        console.log("lista_token_admin", res)
        const [err, obj] = this.safeJsonParse(res);
        if (err) {
          this.exibeToasty("Falha ao interpretar json", "error") 
          console.log('Falha ao interpretar json: ' + err.message);
        } else {
          if (!obj.error) {
            this.fastLinkConvite.token = obj.token
            this.fastLinkConvite.url = `${this.urlFastEAD}convite?token=${obj.token}`
          } else {
            //this.exibeToasty("Erro ao buscar token do convite", "error")
          }          
        }
           
        this.fastLinkConvite.loading = false  
      }).catch(e => {         
        //this.exibeToasty("Erro ao buscar token do convite", "error")
        this.fastLinkConvite.loading = false
      })
    },
    // Estatísticas
    async exibeModalEstatisticasUsuario() {
      this.fastUsuarioCurso = []
      this.showModal("modalEstatisticasUsuario");
      this.buscaEstatisticasUsuario()
        .then((u) => {
          //console.log("buscaEstatisticasUsuario -> then", u);
          const cursos = Array.from(new Set(u.map((a) => a.id_usuario_curso))).map((id_usuario_curso) => {
            return u.find((a) => a.id_usuario_curso === id_usuario_curso);
          });

          cursos.forEach((c, index) => {
            cursos[index].aulas = [];
            u.forEach((a, index2) => {
              if (c.id_usuario_curso == a.id_usuario_curso) {
                let aula_inserida = false;
                cursos[index].aulas.forEach((b) => {
                  if (a.id_usuario_curso_aula == b.id_usuario_curso_aula) {
                    aula_inserida = true;
                  }
                });
                if (!aula_inserida) {
                  cursos[index].aulas.push(a);
                }
                cursos[index].aulas.forEach((b, index2) => {
                  if (!b.atividades) b.atividades = [];
                  if (a.id_usuario_curso_aula == b.id_usuario_curso_aula) {
                    cursos[index].aulas[index2].atividades.push(a);
                  }
                });
              }
            });
          });

          this.fastUsuarioCurso = cursos;
          
        })
        .catch((e) => {
          //console.log("buscaEstatisticasUsuario -> catch", e);
          this.fastUsuarioCurso = [];
          this.showModal("modalEstatisticasUsuario");
        });
    },
    async buscaEstatisticasUsuario(usuario) {
      this.fastUsuarioCursoLoading = true;
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso/estatisticas_usuario?id_usuario_aluno=" + this.fastUsuarioEditar.id_usuario + "&id_plataforma=" + this.$route.params.id_plataforma + "&id_curso=0", this.fastAjaxOptions("GET"));
          let json = await resp.json();
          this.fastUsuarioCursoLoading = false;
          if (json.length) {
            resolve(json);
          } else {
            reject(0);
          }
        } catch (e) {
          this.fastUsuarioCursoLoading = false;
          reject(e);
        }
      });
    },
    async geraRelatorioAlunoExcel(obj) {
      if (obj[0]) {
        this.fastCarregandoAcao = true;
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/desempenho_aluno_provas?id_usuario_aluno=" + obj[0].id_usuario + "&id_plataforma=" + this.$route.params.id_plataforma, this.fastAjaxOptions("GET"));
          let json = await resp.json();
          console.log("geraRelatorioAlunoExcel", json);
          if (JSON.parse(json).file) {
            var link = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
            link.href = JSON.parse(json).file;
            link.target = "_blank";
            var event = new MouseEvent("click", {
              view: window,
              bubbles: false,
              cancelable: true,
            });
            link.dispatchEvent(event);
          } else {
            this.exibeToasty("Nenhuma informação encontrada", "info");
          }

          this.fastCarregandoAcao = false;
        } catch (e) {
          console.log("Erro", e);
          this.fastCarregandoAcao = false;
        }
      } else {
        this.exibeToasty("Nenhuma informação encontrada", "info");
      }
    },
    // Exclusão
    async confirmaUsuarioExcluir() {
      this.$store.state.fastCarregando = true
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/exclui", this.fastAjaxOptions("POST", JSON.stringify(this.fastUsuarioEditar)));
        let json = await resp.json();
        if (!json.length) {

          let fastAlunosTotal = this.fastAlunosTotal.filter((u) => { 
              return u.id_usuario != this.fastUsuarioEditar.id_usuario; 
          });

          this.fastAlunosTotal = fastAlunosTotal
          this.fastAlunosFiltro = fastAlunosTotal

          this.fastUsuarioEditar = {
            id_usuario: "",
            first_name: "",
            last_name: "",
            image: "",
            ativo: "",
            aceito: "",
            data_convite_envio: "",
            data_aceite: null,
            token: "",
            administrador: "",
            edita_cursos: "",
            edita_usuarios: "",
            edita_professores: "",
            edita_plataforma: "",
            edita_matriz: "",
            edita_financeiro: "",
            edita_alunos: "",
            edita_admins: "",
            professor: "",
            aluno: "",
            tesouraria: "",
            secretario: "",
            responsavel: ""
          }
          this.hideModal("modalUsuarioExcluir");
          this.exibeToasty("Usuário excluído com sucesso", "success")
          this.alteraFastNavegacao('exibirAlunos')
          this.$store.state.fastCarregando = false
        } else {
          this.exibeToasty("Erro ao excluir", "error")
          this.$store.state.fastCarregando = false
        }
        
      } catch (e) {
        console.log("Erro", e);
      }
    },
    // Desativação
    async confirmaUsuarioDesativar() {
      this.$store.state.fastCarregando = true
      const fast_plataforma_usuario = {
        id_usuario: this.fastUsuarioEditar.id_usuario,
        id_plataforma: this.$route.params.id_plataforma,
        ativo: "N",
        convite_enviado: "N",
        token: "desativado",
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/atualiza_usuario", this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_usuario)));
        let json = await resp.json();
        this.fastUsuarioEditar.ativo = "N"
        this.fastUsuarioEditar.token = "desativado"
        this.hideModal("modalUsuarioDesativar");
        this.exibeToasty("Usuário desativado com sucesso", "success")
        this.$store.state.fastCarregando = false
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false
      }
    },
    // Ativação
     async confirmaUsuarioAtivar() {
      this.$store.state.fastCarregando = true
      const fast_plataforma_usuario = {
        id_usuario: this.fastUsuarioEditar.id_usuario,
        id_plataforma: this.$route.params.id_plataforma,
        ativo: "S",
        convite_enviado: "S",
        token: "ativado"
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/atualiza_usuario", this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_usuario)));
        let json = await resp.json();
        let obj = Array.from(json);

        this.fastUsuarioEditar.ativo = "S"
        this.fastUsuarioEditar.token = "ativado"

        this.hideModal("modalUsuarioAtivar");
        this.exibeToasty("Usuário ativado com sucesso", "success")
        this.$store.state.fastCarregando = false
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false
      }
    },
    // Reenviar convite
    async confirmaUsuarioReenviar(){
      this.$store.state.fastCarregando = true
      const fast_plataforma_usuario = {        
          id_usuario: this.fastUsuarioEditar.id_usuario,
          id_plataforma: this.$route.params.id_plataforma,
          convite_enviado: "S",
          aceito: "N",
          data_aceite: null,
          ativo: "N",
          token: '1',
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/atualiza_usuario", this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_usuario)));
        let json = await resp.json();
        if (json.length) {
          this.fastUsuarioEditar.convite_enviado = "S"
          this.fastUsuarioEditar.aceito = "N"
          this.fastUsuarioEditar.data_aceite = ""
          this.fastUsuarioEditar.ativo = "N"
          this.fastUsuarioEditar.token = json[0].token;
          
          this.hideModal("modalUsuarioReenviar");
          this.exibeToasty("Convite reenviado com sucesso", "success")
          this.$store.state.fastCarregando = false

          this.fastLinkConvite.token = json[0].token
          this.fastLinkConvite.url = `${this.urlFastEAD}convite?token=${json[0].token}`

        } else {
          this.exibeToasty("Erro ao enviar convite", "error")
          this.$store.state.fastCarregando = false
        }
        
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false
      }
    },
    // Cursos
    async getFastCursos(id_plataforma) {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_plataforma?id_usuario=0&id_plataforma=" + id_plataforma + "&id_curso=", this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let objPlataforma = Array.from(json).filter(c => c.publicado)
        if (objPlataforma.length > 0) {
          this.fastMeusCursos = objPlataforma;
          this.fastMeusCursosFiltro = objPlataforma;
        }
        this.fastMeusCursosLoading = false
      } catch (e) {
        console.log("Erro", e);
        this.fastMeusCursosLoading = false
      }
    },
    async insereUsuarioRestrito(id_usuario, id_curso) {
      this.fastMeusCursosLoading = true
      let fast_curso_plataforma = {
        id_curso: id_curso,
        id_usuario_responsavel: id_usuario,
        id_plataforma: this.$route.params.id_plataforma,
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_restrito/insere_usuario_restrito", this.fastAjaxOptions("POST", JSON.stringify(fast_curso_plataforma)));
        let json = await resp.json();
        this.exibeToasty("Usuário inserido no curso com sucesso", "success")   
        
        this.getUsuariosRestritos(this.fastUsuarioEditar.id_usuario)
        .then((cursos) => {
          this.usuariosRestritos = cursos;
          this.fastMeusCursosLoading = false
        })
        .catch((e) => {
          console.log("getUsuariosRestritos = ERRO", e);
          this.fastMeusCursosLoading = false
        });
        
      } catch (e) {
        console.log("Erro", e)
        this.exibeToasty("Erro ao inserir usuário no curso", "error")
        this.fastMeusCursosLoading = false
      }
    },
    async excluiUsuarioRestrito(id_usuario, id_curso) {
      this.fastMeusCursosLoading = true
      let fast_curso_plataforma = {
        id_curso: id_curso,
        id_usuario_responsavel: id_usuario,
        id_plataforma: this.$route.params.id_plataforma,
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_restrito/exclui_usuario_restrito", this.fastAjaxOptions("POST", JSON.stringify(fast_curso_plataforma)));
        let json = await resp.json();
        this.exibeToasty("Usuário excluído do curso com sucesso", "success")

        this.getUsuariosRestritos(this.fastUsuarioEditar.id_usuario)
        .then((cursos) => {
          this.usuariosRestritos = cursos;
          this.fastMeusCursosLoading = false
        })
        .catch((e) => {
          console.log("getUsuariosRestritos = ERRO", e);
          this.fastMeusCursosLoading = false
        });
       
      } catch (e) {
        console.log("Erro", e);
        this.exibeToasty("Erro ao excluir usuário do curso", "error")
        this.fastMeusCursosLoading = false
      }
    },
    async getUsuariosRestritos(id_usuario) {
      this.fastMeusCursosLoading = true
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_curso_restrito/lista_usuarios_restritos?id_curso=0&id_plataforma=" + this.$route.params.id_plataforma + "&id_usuario=" + id_usuario, this.fastAjaxOptions("GET"));
          let json = await resp.json();
          let obj = Array.from(json);
          this.fastMeusCursosLoading = false
          resolve(obj);          
        } catch (e) {
          this.fastMeusCursosLoading = false
          reject(e);
          console.log("Erro", e);
        }
      });
    },
    verificaUsuarioInseridoCurso(id_curso) {
      return this.usuariosRestritos.filter((c) => c.id_curso == id_curso);
    },
    filtraCursos(e) {
      let text = e.target.value;
      this.fastMeusCursosFiltro = this.fastMeusCursos.filter((e) => {
        if (e.nome_curso) return e.nome_curso.toLowerCase().indexOf(text.toLowerCase()) != "-1" 
        else
          return e.nome_curso_mae.toLowerCase().indexOf(text.toLowerCase()) != "-1" 
      });
    },
    // Cria pessoa
    async criaUsuarioPessoa(usuario) {
      console.log("criaUsuarioPessoa", usuario)
      const fast_pessoa = {
        id_plataforma: this.$route.params.id_plataforma,
        id_usuario: usuario.id_usuario
      }
      this.$store.state.fastCarregando = true;
      this.promisePostFastApi(fast_pessoa, "api/fast_plataforma_usuario/cria_pessoa_usuario").then((res) => {
          console.log("criaUsuarioPessoa", JSON.parse(res))
          if (JSON.parse(res).id_pessoa) {
              this.exibeToasty("Aluno cadastrado com sucesso", "success");
              window.location.href = '/plataforma/' + this.$route.params.id_plataforma + '/secretaria/aluno/' + JSON.parse(res).id_pessoa;
          } else {            
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Erro ao cadastrar pessoa", "error");
          }    
        }).catch((e) => {
          this.$store.state.fastCarregando = false;
          this.exibeToasty("Erro ao cadastrar pessoa", "error");
        });
    },
    // Emails personalizados
    async getFastPlataformaEmail(id_plataforma, emailConvite, emailConfirmacao) {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_email/lista?id_plataforma=" +
            id_plataforma +
            "&id_email=0",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {          

          obj.forEach((e) => {
            if (e.ativo == "S") e.ativo = true;
            else e.ativo = false;
            this.validaOpcoesEmail(e)
          });

          let fastEmailConvite = obj.filter(e => e.nome_email == emailConvite)
          if (fastEmailConvite.length) this.fastEmailConvite = fastEmailConvite[0]
          
          let fastEmailConfirmacao = obj.filter(e => e.nome_email == emailConfirmacao)
          if (fastEmailConfirmacao.length) this.fastEmailConfirmacao = fastEmailConfirmacao[0]
        } 
      } catch (e) {
        console.log("Erro", e);
      }
    },
    editarEmail(fastEmail) {
      if (fastEmail.html_email_padrao != fastEmail.html_email) fastEmail.customizado = "S";
      else fastEmail.customizado = "N";
      
      if (fastEmail.ativo) fastEmail.ativo = "S";
      else fastEmail.ativo = "N";

      if (!fastEmail.email_envio && fastEmail.email_destino == "AD") {
        this.exibeToasty("É necessário informar o email de destino", "error")
      } else {
        this.$store.state.fastCarregando = true;
        this.promisePostFastApi(fastEmail, "api/fast_plataforma_email/atualiza").then(res => {
          this.$store.state.fastCarregando = false;
          this.hideModal("modalEditarEmailConvite");
          this.hideModal("modalEditarEmailConfirmacao");
          this.exibeToasty("Email atualizado com sucesso", "success")
        })
        .catch((e) => {
          this.$store.state.fastCarregando = false;
          this.exibeToasty("Erro ao atualizar email", "error")
        })
      }
    },
    // Calendário histórico
    exibeModalExibirCalendarioHistorico(ano_base){
      this.fasCalendarioNavegacao.exibirCalendario = true
      this.fasCalendarioNavegacao.exibirDiaSelecionado = false
      //anoBaseCalendario

      let objArray = []
      this.fastHistoricoDias.total = objArray
      this.fastHistoricoDias.loading = true

      this.promiseGetFastApi("api/fast_secretaria_aluno_auditoria/lista_dias", `ano=${ano_base}&id_usuario=${this.fastUsuarioEditar.id_usuario}&id_plataforma=${this.$route.params.id_plataforma}`)
      .then((res) => {
        console.log("fast_secretaria_aluno_auditoria", res)
        if (res.length) { 
          res.forEach(e => {
            objArray.push({
              data_evento: `${e.ano}-${e.mes}-${e.dia}T00:00:00`
            })
          })   
          this.fastHistoricoDias.total = objArray  
        }
        this.$bvModal.show('modalExibirCalendarioHistorico')
        this.fastHistoricoDias.loading = false
      })
      .catch(e => {
        this.exibeToasty(e, "error")
        this.fastHistoricoDias.loading = false
      })
      
      //this.showModal('modalExibirCalendarioHistorico')
      
    },
    diaCalendarioClicado(cal){
      console.log("diaCalendarioClicado", cal.date)
      this.fastHistoricoDiaSelecionado.data_selecionada = cal.date

      this.fastHistoricoDiaSelecionado.loading = true
      this.fasCalendarioNavegacao.exibirCalendario = false
      this.fasCalendarioNavegacao.exibirDiaSelecionado = true
      this.fastHistoricoDiaSelecionado.total = []
      this.fastHistoricoDiaSelecionado.filtro = []

      // Evento ajax GET - busca todos os eventos do dia
      let mes = this.fastHistoricoDiaSelecionado.data_selecionada.split('/')[1]
      let dia = this.fastHistoricoDiaSelecionado.data_selecionada.split('/')[0]
      this.promiseGetFastApi("api/fast_secretaria_aluno_auditoria/lista", `ano=${this.anoBaseCalendario}&id_usuario=${this.fastUsuarioEditar.id_usuario}&id_plataforma=${this.$route.params.id_plataforma}&mes=${mes}&dia=${dia}`)
      .then((objArray) => {
        console.log("fast_secretaria_aluno_auditoria", objArray)
        if (objArray.length) { 
          this.fastHistoricoDiaSelecionado.total = objArray
          this.fastHistoricoDiaSelecionado.filtro = objArray
          this.fastHistoricoDiaSelecionado.loading = false
        } else {
          this.fastHistoricoDiaSelecionado.loading = false
        }
      })
      .catch(e => {
        this.exibeToasty(e, "error")
        this.fastHistoricoDiaSelecionado.loading = false
      })

      
    },
    anoCalendarioClicado(ano){
      console.log("anoCalendarioClicado", ano)
      this.anoBaseCalendario = parseInt(ano)
      this.$bvModal.hide('modalExibirCalendarioHistorico')
      this.exibeModalExibirCalendarioHistorico(ano)
    },
    detectaBrowser(user_agent) {
      var isOpera =
        (!!window.opr && !!opr.addons) ||
        !!window.opera ||
        navigator.userAgent.indexOf(" OPR/") >= 0;
      // Firefox 1.0+
      var isFirefox = typeof InstallTrigger !== "undefined";
      // Safari 3.0+ "[object HTMLElementConstructor]"
      var isSafari =
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
          return p.toString() === "[object SafariRemoteNotification]";
        })(
          !window["safari"] ||
            (typeof safari !== "undefined" && safari.pushNotification)
        );
      // Internet Explorer 6-11
      var isIE = /*@cc_on!@*/ false || !!document.documentMode;
      // Edge 20+
      var isEdge = !isIE && !!window.StyleMedia;
      // Chrome 1 - 79
      var isChrome =
        !!window.chrome &&
        (!!window.chrome.webstore || !!window.chrome.runtime);
      // Edge (based on chromium) detection
      var isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") != -1;
      // Blink engine detection
      var isBlink = (isChrome || isOpera) && !!window.CSS;

      if (isOpera) return "Opera";
      else if (isFirefox) return "Firefox";
      else if (isSafari) return "Safari";
      else if (isIE) return "Internet Explorer";
      else if (isEdge) return "Edge";
      else if (isChrome) return "Chrome";
      else return "Não identificado";
    },
    exibeModalUserAgent(user_agent){
      this.fastHistoricoUserAgent = user_agent
      this.showModal('modalUserAgent')
    }
  },
};
</script>

<style scope>
.fast-cursor-pointer:hover {
  background-color: #eee;
}
.fast-cursor-refresh {
  cursor: pointer;
}
/* Ajustes de conflito no editor de texto */
/* CSS do fastead faz editor Quill ficar bugada para edição */
[contenteditable="true"] {
  display: block;
}
/* CSS do fastead faz imagem ficar 100% sempre */
[contenteditable="true"] img {
  width: auto;
}
.fd-app-curso-aulas .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item,
.fd-app-meus-cursos .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item {
  font-family: initial;
}

.ql-container {
  height: auto !important;
}
/* Calendário */
.cal-feriado-marcado {
  background-color: #E10000 !important;
  color: #fff;
}
.cal-turma-marcado {
  background-color: #00e131 !important;
  color: #fff;
  cursor: pointer;
}
.cal-turma-conflito-marcado, .cal-feriado-conflito-marcado  {
  background-color: #fdba01 !important;
  color: #fff;
}
.fast-cursor-pointer:hover {
  background-color: #eee;
}
.fast-ano-selecionado {
  background-color: #eee;
}
.vfc-disabled {
  opacity: 0;
}

</style>
